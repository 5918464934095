import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import LazyImage from '../common/LazyImage'
//import LogoHeader from './LogoHeader.png';
import LogoHeaderNew from './LogoHeaderNew.png';
import WhiteClose from './WhiteClose.png'

const StyledContainer = styled('div')`
  width: 95%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('xxs')`
    height: 15%;
  `}
  ${breakpoint('xs')`
    height: 17%;
  `}
  ${breakpoint('md')`
    width: 90%;
  `}
  ${breakpoint('xl')`
     height: 20%;
  `}
  ${breakpoint('xxl')`
    margin: 2% 0 0 0;
  `}
  ${breakpoint('xxxl')`
    width: 80%;
    margin: 0;
  `}

  @media (max-height: 414px) {
    margin: 2% 0 0 0;
  }
`
const StyledLogo = styled('div')`
  width: 100%;

  ${breakpoint('md')`
    width: 70%;
  `}
  ${breakpoint('xl')`
    width: 50%;
  `}
  ${breakpoint('xxxl')`
    width: 45%;
  `}

  @media (max-height: 414px) {
    width: 50%;
  }
`
export function Roof({children}) {
  return (
    <StyledContainer>
      <StyledLogo>
        <LazyImage img={LogoHeaderNew} width={'70%'}/>
      </StyledLogo>
      {children}
    </StyledContainer>
  );
};

const StyledOpenButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`
const StyledOpenMenuLine = styled('div')`
  background-color: #ffffff;
  width: 2.6rem;
  height: 0.12rem;
  margin: 0 0 0.7rem 0;

  ${breakpoint('md')`
    width: 3.2rem;
    height: 0.2rem;
    margin: 0 0 0.8rem 0;
  `}
  ${breakpoint('xl')`
    width: 5rem;
    margin: 0 0 1.1rem 0;
  `}
`
export function OpenMenuButton({onOpenMenu}) {
  return (
    <StyledOpenButton onClick={onOpenMenu}>
      <StyledOpenMenuLine />
      <StyledOpenMenuLine />
    </StyledOpenButton>
  );
};

const StyledCloseButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1.5rem;
  cursor: pointer;
  margin: 0 2%;

  ${breakpoint('sm')`
  width: 1.8rem;
  `}
  ${breakpoint('md')`
    width: 2.2rem;
  `}
`
export function CloseMenuButton({onCloseMenu}) {
  return (
    <StyledCloseButton onClick={onCloseMenu}>
      <img src={WhiteClose} width={'100%'} alt='WhiteClose'/>
    </StyledCloseButton>
  );
};
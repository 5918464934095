import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css'
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import App from "./App.js"

const theme = {
  breakpoints: {
    xxxs: 0,
    xxs: 375,
    xs: 414,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1024,
    xxl: 1200,
    xxxl: 1500
  }
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App/>
  </ThemeProvider>
, document.getElementById('root'));

serviceWorker.unregister();

import React from 'react';
import 'animate.css/animate.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Hero,
  Label,
  Annotation,
  Solutions,
  // Team,
  CompanyValues,
  Portfolio,
  Feedback,
  Contact,
  Footer
} from './components'
import { ESProjects } from './components/ESProjects';

export default function App() {
  return (
    <>
      <div id='top'><Hero /></div>
      <Annotation />
      <div id='solutions'><Label>Solutions</Label></div>
      <Solutions />
      {/* <div id='team'><Label>Team</Label></div>  
      <Team />  waiting for new photos */}
      <div id='company_values'><CompanyValues /></div>
      <div id='portfolio'><Portfolio>Portfolio</Portfolio></div>
      <div id='feedback'><Feedback>Portfolio</Feedback></div>
      <div id='es_projects'><ESProjects>ES Projects</ESProjects></div>
      <div id='contact_us'><Label>Contact Us</Label></div>
      <Contact />
      <div id='down'><Footer /></div>
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledContainer = styled('div')`
  width: 95%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const StyledSubheader = styled('h2')`
  font-family: DMSans-Bold;
  color: #000000;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  margin: 0;

  ${breakpoint('xxs')`
    font-size: 1.2rem;
    margin: 3% 0;
  `}
  ${breakpoint('md')`
    font-size: 1.9rem;
    margin: 4% 0;
  `}
  ${breakpoint('lg')`
    font-size: 2.5rem;
  `}
  ${breakpoint('xl')`
    font-size: 2.3rem;
    margin: 2% 0;
  `}

  @media (max-height: 414px) {
    font-size: 1.5rem;
    line-height: 0rem;
  }
  @media (max-height: 375px) {
    font-size: 1rem;
    line-height: 1rem;
  }
  @media (max-height: 375px) and (min-width: 1000px) {
    line-height: 0rem;
    margin: 2% 0;
  }
`
const StyledHeader = styled('h1')`
  font-family: Fuji-Bold;
  color: #000000;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  line-height: 4rem;

  ${breakpoint('xxs')`
    font-size: 5rem;
    line-height: 5rem;
    margin: 1% 0 7% 0;
  `}
  ${breakpoint('md')`
    font-size: 6rem;
    line-height: 6rem;
  `}
  ${breakpoint('xl')`
    font-size: 8rem;
    line-height: 8rem;
  `}
  ${breakpoint('xxl')`
    margin: 1% 0 3% 0;
  `}
  ${breakpoint('xxxl')`
   margin: 0 0 5% 0;
  `}

  @media (max-height: 768px) {
    font-size: 3.5em;
    line-height: 3.5rem;
    margin: 0 0 4% 0;
  }
  @media (max-height: 460px) {
    font-size: 2.5em;
    line-height: 2.5rem;
    margin: 0 0 2% 0;
  }
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Medium;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  margin: 5%;

  ${breakpoint('xxs')`
    font-size: 1rem;
    margin: 3% 0;
  `}
  ${breakpoint('sm')`
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 0 12% 2% 12%;
  `}
  ${breakpoint('md')`
    font-size: 1.7rem;
    line-height: 2.5rem;
  `}
  ${breakpoint('xxxl')`
    line-height: 2.5rem;
    margin: 0 15% 2% 15%;
  `}

  @media (max-height: 768px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  @media (max-height: 375px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`
export function Header() {
  return (
    <StyledContainer>
      <StyledSubheader>Stay focused on what matters most</StyledSubheader>
      <StyledHeader>Business made better</StyledHeader>
      <StyledParagraph>DevLab is a technology development team whose main focus is to improve management and business processes through efficiency and optimization. Our extensive expertise and innovative enterprise solutions help businesses grow and thrive</StyledParagraph>
    </StyledContainer>
  );
};

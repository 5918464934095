import React, { useState } from 'react';
import styled from 'styled-components';
import { ScrollButton } from '../common/ScrollButton'
import { Header } from './Header'
import { Roof, OpenMenuButton } from './Roof'
import { Menu } from './Menu'

const { PUBLIC_URL } = process.env;

const StyledFVideo = styled('video')`
  overflow: hidden;
  object-fit: cover;
  opacity: 0.6;
  width: 100%;
  height: 100%;

  @media (max-height: 470px) and (max-width: 470px) {
    visibility: hidden;
  }
  @media (max-height: 300px) and (max-width: 770px) {
    visibility: hidden;
  }
`
function BackHero() {
  return (
    <StyledFVideo
      src={`${PUBLIC_URL}/HeroVideo.mp4`}
      autoPlay
      preload={'auto'}
      loop
      muted
    >
    </StyledFVideo>
  );
};

const StyledFrontHero = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`
function FrontHero({onOpenMenu}) {
  return (
    <StyledFrontHero>
      <Roof>
        <OpenMenuButton onOpenMenu={onOpenMenu}/>
      </Roof>
      <Header />
      <ScrollButton scrollTo='down'>Contact us</ScrollButton>
    </StyledFrontHero>
  );
};

const StyledContainer = styled('div')`
  overflow: hidden;
  display: block;
  max-width: 100vw;
  height: 100vh;
`
export function Hero() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledContainer>
      <BackHero />
      <FrontHero onOpenMenu={() => setIsOpen(true)} />
      {isOpen && (
        <Menu onCloseMenu={() => setIsOpen(false)} />
      )}
    </StyledContainer>
  );
};

import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'react-scroll';

const StyledScrollDown = styled(Link)`
  width: 5.8rem;
  height: 2.1rem;
  background-color: transparent;
  border: 2px solid #000000;
  border-radius: 2rem;
  cursor: pointer;
  margin: 0 0 5% 0;
  :active, :focus {
    outline: none;
  }

  ${breakpoint('xxs')`
    width: 6.8rem;
    height: 2.4rem;
  `}
  ${breakpoint('md')`
    width: 8rem;
    height: 2.7rem;
  `}
  ${breakpoint('xl')`
    width: 9rem;
    height: 3.2rem;
  `}
  ${breakpoint('xxl')`
    margin: 0 0 2% 0;
  `}

  @media (max-height: 375px) {
    width: 5.4rem;
    height: 1.8rem;
    margin: 0 0 1% 0;
  }
  @media (max-height: 470px) and (max-width: 470px) {
    visibility: hidden;
  }
`
const StyledScrollDownText = styled('p')`
  font-family: DMSans-Medium;
  font-weight: 400;
  color: #000000;
  font-size: 0.9rem;
  text-align: center;
  line-height: 0.2rem;

  ${breakpoint('xxs')`
   font-size: 1rem;
   line-height: 0.4rem;
  `}
  ${breakpoint('md')`
    font-size: 1.1rem;
    line-height: 0.7rem;
  `}
  ${breakpoint('xxl')`
    line-height: 0.8rem;
  `}
  @media (max-height: 375px) {
    font-size: 0.8rem;
    line-height: 0.2rem;
  }
`
export function ScrollButton({children, scrollTo}) {
  return (
    <StyledScrollDown to={scrollTo} smooth={true}>
      <StyledScrollDownText>{children}</StyledScrollDownText>
    </StyledScrollDown>
  );
};
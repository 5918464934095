import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyImage from '../common/LazyImage';

const StyledNoteAnimationContainer = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0 0 0;

  ${breakpoint('xxl')`
    width: 50%;
    margin: 1rem 4rem;
  `}
  ${breakpoint('xxxl')`
    margin: 5rem 6rem;
  `}
`
const StyledHeader = styled('h1')`
  font-family: DMSans-Regular;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: left;

  ${breakpoint('md')`
    font-size: 2.1rem;
  `}
  ${breakpoint('xl')`
    font-size: 2.3rem;
  `}
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;

  ${breakpoint('md')`
    font-size: 1.4em;
    line-height: 1.9rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.5rem;
    line-height: 2.2rem;
  `}
`
export function Note({header, paragraph}) {
  return (
    <StyledNoteAnimationContainer animateIn='fadeInUp' animateOnce={true}>
        <StyledHeader>{header}</StyledHeader>
        <StyledParagraph>{paragraph}</StyledParagraph>
    </StyledNoteAnimationContainer>
  );
};

const StyledShadow = styled('div')`
  position:relative;
  width: 15rem;
  height: 20rem;
  background-color: #1a1a1a;
  margin: 2rem auto 0 auto;

  ${breakpoint('md')`
    width: 22rem;
    height: 29rem;
  `}
  ${breakpoint('xl')`
    width: 30rem;
    height: 40rem;
  `}
`
const StyledFrontImg = styled('div')`
  position: absolute;
  left: -6%;
  bottom: 1rem;
  width: 100%;
  height: 100%;
  background-color: #b9b9b9;

  ${breakpoint('md')`
    bottom: 1.3rem;
  `}
  ${breakpoint('xl')`
    bottom: 1.8rem;
  `}
`
export function ImgBox({img}) {
  return (
    <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
      <StyledShadow>
        <StyledFrontImg>
            <LazyImage img={img} width={'100%'} height={'100%'} alt='Solution'/>
        </StyledFrontImg>
      </StyledShadow>
    </ScrollAnimation>
  );
};

import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import LogoPolivektris from './LogoPolivektris.png';
import LogoCCG from './LogoCCG.jpg';
import LogoLithome from './LogoLithome.jpg';
import LazyImage from '../common/LazyImage';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 20%;
  margin: 5% 0 0 0;

  ${breakpoint('xl')`
    width: 80%;
  `}
  ${breakpoint('xxl')`
    margin: 3% 0 0 0;
  `}
  ${breakpoint('xxxl')`
    width: 65%;
  `}
`
const StyledHeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 2% 0;
`
const StyledPartnersHeader = styled('h3')`
  font-family: DMSans-Regular;
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin: 5% 0 5% 0;

  ${breakpoint('md')`
    font-size: 0.8rem;
  `}
  ${breakpoint('xs')`
    font-size: 0.9rem;
  `}
  ${breakpoint('md')`
    font-size: 1.1rem;
  `}
  ${breakpoint('xl')`
    font-size: 1.4rem;
  `}
`
const StyledPartnersContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 0 0 10% 0;

  ${breakpoint('xs')`
    margin: 0 0 12% 0;
  `}
  ${breakpoint('md')`
    width: 80%;
    margin: 0 0 14% 0;
  `}
  ${breakpoint('xl')`
    width: 90%;
    margin: 0 0 10% 0;
  `}
  ${breakpoint('xxl')`
    margin: 0 0 8% 0;
  `}
`
const StyledImgOuterContainer = styled('div')`
  width: auto;
  cursor: pointer;
`
const StyledImgInnerContainer = styled(StyledImgOuterContainer)`
  margin: 0 10%;

  ${breakpoint('xxs')`
    margin: 0 12%;
  `}
  ${breakpoint('md')`
    margin: 0 20%;
  `}
`
const StyledLink = styled('a')`
  text-decoration: none;
`
export function Partners() {
  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledPartnersHeader>Partners</StyledPartnersHeader>
      </StyledHeaderContainer>
      <StyledPartnersContainer>

      <StyledLink href={'http://www.cargocontinental.lt'} target='_blank'>
        <StyledImgOuterContainer>
          <LazyImage img={LogoCCG} width={'100%'} alt='CCG'/>
        </StyledImgOuterContainer>
        </StyledLink>

        <StyledLink href={'http://www.polivektris.lt'} target='_blank'>
          <StyledImgInnerContainer>
            <LazyImage img={LogoPolivektris} width={'100%'} alt='Polivektris'/>
          </StyledImgInnerContainer>
        </StyledLink>
        
        <StyledLink href={'http://www.lithome.lt'} target='_blank'>
          <StyledImgOuterContainer>
            <LazyImage img={LogoLithome} width={'100%'} alt='LLithome'/>
          </StyledImgOuterContainer>
        </StyledLink>

      </StyledPartnersContainer>
    </StyledContainer>
  );
};
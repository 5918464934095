import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledFormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  ${breakpoint('xxl')`
    width: 58%;
  `}
  ${breakpoint('xxxl')`
    width: 50%;
  `}
`
const StyledLabelBox = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${breakpoint('xl')`
    flex-direction: row;
  `}
`
const StyledLabel = styled('label')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: DMSans-Regular;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 3% 0 0 0;

  ${breakpoint('xl')`
    font-size: 1.5rem;
    line-height: 2.7rem;
    width: 47%;
  `}
  ${breakpoint('xxl')`
    font-size: 1.3rem;
  `}
`
const StyledInput = styled('input')`
  border: 1px solid #1a1a1a;
  height: 3.4rem;
`
const StyledTextareaLabel = styled('label')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: DMSans-Regular;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 3% 0 0 0;

  ${breakpoint('xl')`
    font-size: 1.5rem;
    line-height: 2.7rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.3rem;
  `}
`
const StyledTextarea = styled('textarea')`
  resize: none;
  border: 1px solid #1a1a1a;
  height: 15rem;
`
const StyledButton = styled('button')`
  width: 100%;
  height: 3.4rem;
  font-family: DMSans-Regular;
  font-weight: 400;
  font-size: 1.2rem;
  background-color: #1a1a1a;
  color: #ffffff;
  cursor: pointer;
  margin: 8% 0 15% 0;
  border: 1px solid #1a1a1a;
  :hover {
    background-color: #5a5f70;
  };
  :active, :focus {
    outline: none;
  }

  ${breakpoint('md')`
    margin: 8% 0;
  `}
  ${breakpoint('xl')`
    font-size: 1.5rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.3rem;
  `}
`
export function Form() {
  return (
    <StyledFormContainer method='POST' action='https://formspree.io/aa@devlaboratory.com' id='contact-form'>
      <StyledLabelBox>
        <StyledLabel>Name<StyledInput type='text' name='name' required/> </StyledLabel>
        <StyledLabel> Company <StyledInput type='text' name='company' required/> </StyledLabel>
      </StyledLabelBox>
      <StyledLabelBox>
        <StyledLabel> Phone <StyledInput type='text' name='phone' required/> </StyledLabel>
        <StyledLabel> Email <StyledInput type='text' name='_replyto' required/> </StyledLabel>
      </StyledLabelBox>
      <StyledTextareaLabel> Message  <StyledTextarea type='text' name='message' required ></StyledTextarea> </StyledTextareaLabel>
      <StyledButton type='submit' value='Send' form='contact-form'>Send</StyledButton>
    </StyledFormContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
//import LogoFooter from './LogoFooter.png';
import LogoFooterNew from './LogoFooterNew.png';
import LazyImage from '../common/LazyImage';
import LinkedInImage from './In-Blue-26@2x.png';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 10%;

  ${breakpoint('xxl')`
    margin: 0 20%;
  `}
`
const StyledDivider = styled('div')`
  background-color: #707070;
  height: 1px;
`
const StyledFooterBox = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 7% 0;

  ${breakpoint('xl')`
    flex-direction: row;
    margin: 5% 0;
  `}
`
const StyledLogo = styled('div')`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;

  ${breakpoint('xxs')`
    width: 70%;
  `}
  ${breakpoint('xs')`
    width: 60%;
  `}
  ${breakpoint('md')`
    width: 45%;
  `}
  ${breakpoint('xl')`
    width: 30%;
    justify-content: flex-start;
    margin: 0;
  `}
  ${breakpoint('xxxl')`
    width: 23%;
  `}
`
const StyledCopyright = styled('p')`
  font-family: DMSans-Regular;
  color: #000000;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-align: center;

  ${breakpoint('md')`
    font-size: 1.2rem;
  `}
`
const url = "https://www.linkedin.com/company/dev-laboratory-com"
export function Footer() {
  return (
    <StyledContainer>
      <StyledDivider />
      <StyledFooterBox>
        <StyledLogo>
          <LazyImage img={LogoFooterNew} width={'100%'} alt='Logo' />
        </StyledLogo>
        <StyledCopyright>© {new Date().getFullYear()} devlaboratory.com All rights reserved.</StyledCopyright>
        <a href={url} target='blank'><LazyImage img={LinkedInImage} width={24} /></a>
      </StyledFooterBox>
    </StyledContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Projects } from './Projects'
import { Partners } from './Partners'

const StyledContainer = styled('div')`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #1a1a1a;
  width: 100%;
`
const StyledHeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 8% 0;
`
const StyledHeader = styled('h2')`
  font-family: DMSans-Bold;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;

  ${breakpoint('md')`
    font-size: 4rem;
  `}
  ${breakpoint('xl')`
    font-size: 5rem;
  `}
  ${breakpoint('xxl')`
    font-size: 6rem;
  `}
`
export function Portfolio() {
  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledHeader>Portfolio</StyledHeader>
      </StyledHeaderContainer>
      <Projects />
      <Partners />
    </StyledContainer>
  );
}
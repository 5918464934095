import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import BlackClose from './BlackClose.png'

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${breakpoint('xl')`
    flex-direction: row;
  `}
`
const StyledAnimationInfoContainer = styled(ScrollAnimation)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto;

  ${breakpoint('xl')`
    width: 60%;
    margin: 2%;
  `}
  ${breakpoint('xxl')`
    width: 60%;
    margin: 5%;
  `}
`
const StyledCloseButtonBox = styled('div')`
  display: flex;
  justify-content: flex-end;
`
const StyledCloseButton = styled('div')`
  display: flex;
  cursor: pointer;
  width: 1.2rem;

  ${breakpoint('xl')`
    width: 1.8rem;
  `}
`
const StyledPrimaryText = styled('h2')`
  font-family: DMSans-Bold;
  color: #1a1a1a;
  font-size: 1.7rem;
  font-weight: 400;
  text-align: center;
  margin: 0;

  ${breakpoint('md')`
    font-size: 2rem;
  `}
  ${breakpoint('xl')`
    font-size: 2.7rem;
  `}
`
const StyledSecondaryText = styled('h3')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  margin: 2% 0 0 0;

  ${breakpoint('md')`
    font-size: 1.5rem;
  `}
  ${breakpoint('xl')`
    font-size: 1.9rem;
  `}
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;

  ${breakpoint('md')`
    font-size: 1.4em;
    line-height: 1.9rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.5rem;
    line-height: 2.2rem;
  `}
`
const StyledLink = styled('a')`
  text-decoration: none;
`
export function Person({link, img, primary, secondary, paragraph, onClose}) {
  return (
    <StyledContainer>
      <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
        <img src={img} width={'100%'} alt='Person'/>
      </ScrollAnimation>
      <StyledAnimationInfoContainer animateIn='fadeInUp' animateOnce={true}>
        <StyledCloseButtonBox>
          <StyledCloseButton onClick={onClose}>
            <img src={BlackClose} width={'100%'} alt='BlackClose'/>
          </StyledCloseButton>
        </StyledCloseButtonBox>
        <StyledLink href={link} target='_blank'><StyledPrimaryText>{primary}</StyledPrimaryText></StyledLink>
        <StyledSecondaryText>{secondary}</StyledSecondaryText>
        <StyledParagraph>{paragraph}</StyledParagraph>
      </StyledAnimationInfoContainer>
    </StyledContainer>
  );
};
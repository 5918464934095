import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Note, ImgBox } from './Article'
import SolutionsImg_1 from './SolutionsImg_1.jpg';
import SolutionsImg_2 from './SolutionsImg_2.jpg';
import SolutionsImg_3 from './SolutionsImg_3.jpg';

const StyledArticleContainer = styled('div')`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;

  ${breakpoint('xs')`
    margin: 4rem auto;
  `}
  ${breakpoint('md')`
    margin: 6rem auto;
  `}
  ${breakpoint('xxl')`
    width: 85%;
    flex-direction: row;
    margin: 8rem auto;
  `}
  ${breakpoint('xxxl')`
    width: 80%;
  `}
`
const StyledArticleReverseContainer = styled(StyledArticleContainer)`
  ${breakpoint('xxl')`
    flex-direction: row-reverse;
  `}
`
function FirstArticle() {
  return (
    <StyledArticleReverseContainer>
      <ImgBox img={SolutionsImg_1}/>
      <Note
        header='Business Optimization'
        paragraph='Digitalize and optimize your company’s repetitive and time-consuming processes. Set KPI’s for team members to clarify everyone’s duties and responsibilities. Track progress daily from your laptop, tablet or smartphone. Get Real Time Notifications so you can react immediately. Share information with easy to understand graphs and charts. DevLab’s Business Optimization Solutions are here to make your day-to-day operations easy, efficient and cost-effective.'
      />
    </StyledArticleReverseContainer>
  );
};

function SecondArticle() {
  return (
    <StyledArticleContainer>
      <ImgBox img={SolutionsImg_2}/>
      <Note
        header='Mobile App Development'
        paragraph='Today’s digital landscape is a dynamic and constantly changing one. To stay up-to-date, DevLab’s Mobile App Development team will help you build the perfect app for both iOS and Android. Grow your audience, get to know your customers, make use of personalized offers and build a customer-oriented business. Start building your perfect Mobile App with DevLabs today!'
      />
    </StyledArticleContainer>
  );
};

function ThirdArticle() {
  return (
    <StyledArticleReverseContainer>
      <ImgBox img={SolutionsImg_3}/>
      <Note
        header='Dev Team Extension'
        paragraph='Are you a part of an IT company? If your workload is too much for your team, DevLabs is here to lend a helping hand! Our DevTeam Extension is the perfect solution for a short time coding tasks and projects. Take a deep breath and contact us today for a free consultation! Will make sure you meet all your deadlines.'
      />
    </StyledArticleReverseContainer>
  );
};

const StyledContainer = styled('div')`
  ${breakpoint('xxl')`
    margin: 15rem auto;
  `}
`
export function Solutions() {
  return (
    <StyledContainer>
      <FirstArticle />
      <SecondArticle />
      <ThirdArticle />
    </StyledContainer>
  );
};
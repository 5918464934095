import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LazyImage from '../common/LazyImage';

const StyledAnimationContainer = styled(ScrollAnimation)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 1rem;
`
const StyledValueTextContainer = styled('div')`
  width: 95%;
`
const StyledPrimaryText = styled('h2')`
  font-family: DMSans-Regular;
  color: #1a1a1a;
  font-size: 2.3rem;
  font-weight: 400;
  text-align: center;
  
  ${breakpoint('xl')`
    font-size: 3rem;
  `}
  ${breakpoint('xxl')`
    font-size: 2.8rem;
  `}
  ${breakpoint('xxxl')`
    font-size: 3rem;
  `}
`
const StyledSecondaryText = styled('h3')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;

  ${breakpoint('md')`
    font-size: 1.4em;
    line-height: 1.9rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.5rem;
    line-height: 2.2rem;
  `}
`
const StyledImgContainer = styled('div')`
  width: 12rem;
  height: auto;

  ${breakpoint('xl')`
    width: 15rem;
  `}
`
export function Value({img, primary, secondary, delay}) {
  return (
    <StyledAnimationContainer animateIn='fadeIn' animateOnce={true} duration={2} delay={delay}>
      <StyledImgContainer>
        <LazyImage img={img} width={'100%'} height={'100%'} alt='Value'/>
      </StyledImgContainer>
      <StyledValueTextContainer>
        <StyledPrimaryText>{primary}</StyledPrimaryText>
        <StyledSecondaryText>{secondary}</StyledSecondaryText>
      </StyledValueTextContainer>
    </StyledAnimationContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';

const StyledAnimationContainer = styled(ScrollAnimation)`
  display: flex;
  flex-direction: column;

  ${breakpoint('xxl')`
    width: 38%;
  `}
  ${breakpoint('xxxl')`
    width: 35%;
  `}
`
const StyledCompanyName = styled('h3')`
  font-family: DMSans-Bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1.5rem 0;

  ${breakpoint('xs')`
    font-size: 1.7rem;
    line-height: 2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.9rem;
  `}
  ${breakpoint('xl')`
    font-size: 2rem;
  `}
  ${breakpoint('xxl')`
    text-align: left;
    font-size: 1.8rem;
  `}
`
const StyledAddress = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0;

  ${breakpoint('xs')`
    font-size: 1.2rem;
    line-height: 2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.4rem;
  `}
  ${breakpoint('xl')`
    font-size: 1.5rem;
    line-height: 2.1rem;
  `}
  ${breakpoint('xxl')`
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.2rem;
  `}
`
export function Address() {
  return (
    <StyledAnimationContainer animateIn='fadeInUp' animateOnce={true}>
      <StyledCompanyName>
        Išskirtinis mąstymas, UAB
      </StyledCompanyName>
      <StyledAddress>Registration code: 304725390</StyledAddress>
      <StyledAddress>VAT code: LT100011876710</StyledAddress>
      <StyledAddress>Address: Jurgio Baltrušaičio g. 9-146,</StyledAddress>
      <StyledAddress>LT-06145 Vilnius</StyledAddress>
    </StyledAnimationContainer>
  );
};
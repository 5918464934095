import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';

const StyledNoteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
`
const StyledHeader = styled('h2')`
  font-family: DMSans-Medium;
  color: #1a1a1a;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-align: left;

  ${breakpoint('md')`
    font-size: 2.2rem;
  `}
  ${breakpoint('xl')`
    font-size: 2.4rem;
  `}
  ${breakpoint('xxl')`
    font-size: 2.6rem;
  `}
`
const StyledSubHeader = styled('h3')`
  font-family: DMSans-Regular;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1rem;
  margin: 0;

  ${breakpoint('sm')`
    font-size: 1.2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.5rem;
  `}
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  margin: 2.5rem 0;

  ${breakpoint('xs')`
    font-size: 1.2rem;
    line-height: 2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.4rem;
  `}
  ${breakpoint('xl')`
    margin: 4rem 0;
  `}
  ${breakpoint('xxl')`
    font-size: 1.6rem;
    line-height: 2.2rem;
  `}
`
function Note({header, subheader, paragraph}) {
  return (
    <StyledNoteContainer>
      <StyledHeader>{header}</StyledHeader>
      <StyledSubHeader>{subheader}</StyledSubHeader>
      <StyledParagraph>{paragraph}</StyledParagraph>
    </StyledNoteContainer>
  );
};

const StyledContainer = styled(ScrollAnimation)`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 5% auto 20% auto;

  ${breakpoint('md')`
    width: 70%;
    margin: 5% auto 16% auto;
 `}
  ${breakpoint('xxl')`
    width: 60%;
    margin: 5% auto 10% auto;
  `}
`
const StyledRoofCotainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
`
const StyledQuotesBox = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
const StyledQuotes = styled('div')`
  line-height: 7rem;
  color: #b9b9b9;
  font-size: 6rem;
  font-weight: 700;
  font-family: Arial;
  transform: scale(1, -1);
  letter-spacing: -1rem;

  ${breakpoint('xl')`
    font-size: 8rem;
    line-height: 8rem;
  `}
  ${breakpoint('xxl')`
    font-size: 9.7rem;
    line-height: 9.7rem;
  `}
`
export class Feedback extends React.Component {
  render() {
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    };
    return (
      <StyledContainer animateIn='fadeInUp' animateOnce={true}>
        <StyledRoofCotainer>
          <StyledQuotesBox>
            <StyledQuotes>``</StyledQuotes>
          </StyledQuotesBox>
        </StyledRoofCotainer>
        <Slider {...settings}>
          <Note
            header='UAB Polivektris'
            subheader='Sales Manager Irina Kazakevič-Dilienė'
            paragraph='We were thrilled with our experience with DevLab. They made life a lot easier for us! From the beginning, they fully understood our issues and had a clear vision of how those issues needed to be solved. After the IzI Logistics solution was implemented we managed to cut headcount in our Logistics department by 50% and decreased delivery costs by 18%.'
          >
          </Note>
          <Note
            header='UAB LITHOME'
            subheader='Sales Manager Rasa Radovičienė'
            paragraph='We knew we needed some help, but we didn’t realize we were doing things so inefficiently.  Now, our construction managers and the whole sales team use the digital platform to oversee and track progress of all ongoing projects at the same time. It gives us the ability to plan far ahead and stay updated 24/7 via web or mobile channels. By using DevLab’s digital platform, we managed to cut unnecessary meeting time and focus more on sales and development of new projects.'
          >
          </Note>
          <Note
            header='UAB Cargo Cantinental Group'
            subheader='General Manager Anton Saveljev'
            paragraph='DevLab helped us significantly improve our business management and took our company to a whole new level. We were looking for a tailored solution to manage our internal company processes and DevLab thankfully fulfilled all our needs. Firstly, we can now track our fleet more accurately and efficiently. Secondly, we follow the status of our stock on-line and get notifications when replenishment is needed. Finally, we can skip routine calls with drivers as all necessary information is conveniently placed on a single dashboard.'
          >
          </Note>
        </Slider>
      </StyledContainer>
    );
  };
};

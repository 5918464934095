import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-scroll";
import LazyImage from '../common/LazyImage';

const StyledAvatarAnimationContainer = styled(ScrollAnimation)`
  display: flex;
  flex-direction: column;
  width: 50%;
`
const StyledArticleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 5%;
`
const StyledPrimaryText = styled('h2')`
  font-family: DMSans-Regular;
  color: #1a1a1a;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  cursor: pointer;

  ${breakpoint('xs')`
    font-size: 0.9rem;
  `}
  ${breakpoint('xxs')`
    font-size: 1rem;
  `}
  ${breakpoint('sm')`
    font-size: 1.4rem;
  `}
  ${breakpoint('md')`
    font-size: 1.8rem;
  `}
  ${breakpoint('xl')`
    font-size: 2.2rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.6rem;
  `}
  ${breakpoint('xxxl')`
    font-size: 1.8rem;
  `}
`
const StyledSecondaryText = styled('h3')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
  margin: 3% 0;

  ${breakpoint('xxs')`
    font-size: 1rem;
  `}
  ${breakpoint('sm')`
    font-size: 1.2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.5rem;
  `}
  ${breakpoint('xl')`
    font-size: 1.9rem;
  `}
  ${breakpoint('xxl')`
    font-size: 1.4rem;
  `}
  ${breakpoint('xxxl')`
    font-size: 1.6rem;
  `}
`
export function Avatar({img, primary, secondary, delay, onOpen, to}) {
  return (
    <StyledAvatarAnimationContainer animateIn='fadeIn' animateOnce={true} duration={2} delay={delay}>
      <LazyImage img={img} width={'100%'} height={'100%'}/>
      <StyledArticleContainer>
        <Link to={to} smooth={true}><StyledPrimaryText onClick={onOpen}>{primary}</StyledPrimaryText></Link>
        <StyledSecondaryText>{secondary}</StyledSecondaryText>
      </StyledArticleContainer>
    </StyledAvatarAnimationContainer>
  );
};

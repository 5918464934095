import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Slider from 'react-slick';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import './custom_dots.css';
import PortfolioImg_1 from './PortfolioImg_1.jpg';
import PortfolioImg_2 from './PortfolioImg_2.jpg';
import PortfolioImg_3 from './PortfolioImg_3.jpg';

const StyledValueTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 1rem;

  ${breakpoint('xxs')`
    margin: 1.5rem;
  `}
  ${breakpoint('md')`
    position: absolute;
    top: 30%;
    left: 7%;
    margin: 0;
  `}
  ${breakpoint('xxxl')`
    top: 35%;
    left: 14%
  `}
`
const StyledPrimaryText = styled('h2')`
  font-family: DMSans-Bold;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: left;
  margin: 0 0 1rem 0;
  
  ${breakpoint('md')`
    font-size: 2.3rem;
  `}
  ${breakpoint('xl')`
    font-size: 3rem;
    line-height: 3rem;
  `}
`
const StyledSecondaryText = styled('h3')`
  font-family: DMSans-Regular;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: left;
  margin: 0;

  ${breakpoint('md')`
    font-size: 1.2rem;
    line-height: 1.5rem;
  `}
  ${breakpoint('xl')`
    font-size: 1.5rem;
    line-height: 2rem;
  `}
`
function StyledText({primary, secondary, subSecondary}) {
  return (
    <StyledValueTextContainer>
      <StyledPrimaryText>{primary}</StyledPrimaryText>
      <StyledSecondaryText>{secondary}</StyledSecondaryText>
      <StyledSecondaryText>{subSecondary}</StyledSecondaryText>
    </StyledValueTextContainer>
  );
};

const StyledProjectContainer = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 auto 3%;
  flex-direction: column;

  ${breakpoint('md')`
    position: relative;
    flex-direction: row;
  `}
`
const StyledImgContainer = styled('div')`
  overflow: hidden
  width: 100%;
  height: 13rem;
  overflow: hidden;
  opacity: 0.5;
  background-color: #b9b9b9;

  ${breakpoint('xxs')`
    height: 15rem;
  `}
  ${breakpoint('xs')`
    height: 20rem;
  `}
  ${breakpoint('sm')`
    height: 30rem;
  `}
  ${breakpoint('md')`
    width: 75%;
    height: 26rem;
    margin: 0 auto;
  `}
  ${breakpoint('lg')`
    height: 28rem;
  `}
  ${breakpoint('xl')`
    height: 32rem;
  `}
  ${breakpoint('xl')`
    height: 39rem;
  `}
  ${breakpoint('xxxl')`
    width: 58%;
    height: 42rem;
  `}
`
const StyledLink = styled('a')`
  text-decoration: none;
`
function FirstProject() {
  return (
    <StyledProjectContainer>
      <StyledImgContainer>
        <img src={PortfolioImg_1} width={'100%'} height={'100%'} alt={'Portfolio'}/>
      </StyledImgContainer>
      <StyledLink href={'http://www.polivektris.lt'} target='_blank'>
        <StyledText
          primary='Polivektris LTD'
          secondary='IzI Logistic - Digitalization of manufacturing processes'
          subSecondary='(packaging, polymer production)'
        />
      </StyledLink>
    </StyledProjectContainer>
  );
};

function SecondProject() {
  return (
    <StyledProjectContainer>
      <StyledImgContainer>
        <img src={PortfolioImg_2} width={'100%'} height={'100%'} alt={'Portfolio'}/>
      </StyledImgContainer>
      <StyledLink href={'http://www.lithome.lt'} target='_blank'>
        <StyledText
          primary='Lithome LTD'
          secondary='Digital construction-sales management platform'
        />
      </StyledLink>
    </StyledProjectContainer>
  );
};

function ThirdProject() {
  return (
    <StyledProjectContainer>
      <StyledImgContainer>
        <img src={PortfolioImg_3} width={'100%'} height={'100%'} alt={'Portfolio'} />
      </StyledImgContainer>
      <StyledLink href={'http://www.cargocontinental.lt'} target='_blank'>
        <StyledText
          primary='CCG LTD'
          secondary='Cargo fleet management platform'
        />
      </StyledLink>
    </StyledProjectContainer>
  );
};

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40%;
  margin: 0 0 10% 0;

  ${breakpoint('md')`
    margin: 0 0 5% 0;
  `}
`
export class Projects extends React.Component {
  render() {
    const settings = {
      dots: true,
      dotsClass: 'slick-dots custom-slick-dots',
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    };
    return (
      <LazyLoadComponent threshold={500}>
        <StyledContainer>
          <Slider {...settings}>
            <FirstProject />
            <SecondProject />
            <ThirdProject />
          </Slider>
        </StyledContainer>
      </LazyLoadComponent>
    );
  };
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';

const StyledOverflowHidingContainer = styled('div')`
  overflow: hidden;
`
const StyledAnimationContainer = styled(ScrollAnimation)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const StyledShadow = styled('div')`
  position:relative;
  width: 80%;
  height: 10.6rem;
  background-color: #1a1a1a;

  ${breakpoint('sm')`
    height: 12.5rem;
  `}
  ${breakpoint('sm')`
    height: 15.6rem;
  `}
  ${breakpoint('md')`
    height: 18.8rem;
  `}
  ${breakpoint('lg')`
  height: 21.8rem;
  `}
  ${breakpoint('xl')`
    height: 25rem;
  `}
  ${breakpoint('xxl')`
    height: 28rem;
  `}
`
const StyledFront = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  width: 108%;
  height: 87%;
  background-color: #b9b9b9;
  
  ${breakpoint('md')`
    width: 106%;
  `}
  ${breakpoint('xl')`
    width: 105.5%;
  `}
  ${breakpoint('xxl')`
    width: 105%;
  `}
  ${breakpoint('xxxl')`
    width: 104.5%;
  `}
`
const StyledLabelHeader = styled('h2')`
  font-family: DMSans-Bold;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  overflow: hidden;
  margin: 0 0 4% 5%;

  ${breakpoint('md')`
    font-size: 3.8rem;
  `}
  ${breakpoint('xl')`
    font-size: 4.5em;
  `}
  ${breakpoint('xxxl')`
    font-size: 5em;
  `}
`
export function Label({children}) {
  return (
    <StyledOverflowHidingContainer>
      <StyledAnimationContainer animateIn='fadeInRight' animateOnce={true}>
        <StyledShadow>
          <StyledFront>
            <ScrollAnimation animateIn='fadeIn' duration={3} animateOnce={true}>
              <StyledLabelHeader>
                {children}
              </StyledLabelHeader>
            </ScrollAnimation>
          </StyledFront>
        </StyledShadow>
      </StyledAnimationContainer>
    </StyledOverflowHidingContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledAnimationAnnotationBox = styled(ScrollAnimation)`
  width: 85%;
  height: auto;
  display: flex;
  margin: 12rem 0;

  ${breakpoint('md')`
    margin: 8%;
  `}
  ${breakpoint('md')`
    width: 70%;
 `}
  ${breakpoint('xxl')`
    width: 60%;
  `}
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;

  ${breakpoint('xs')`
    font-size: 1.2rem;
    line-height: 2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.4em;
  `}
  ${breakpoint('xxl')`
    font-size: 1.6rem;
    line-height: 2.2rem;
`}
`
export function Annotation() {
  return (
    <StyledContainer>
      <StyledAnimationAnnotationBox animateIn='fadeInUp' animateOnce={true}>
        <StyledParagraph>
          Starting a business is complicated. As you build your enterprise from the ground up and grow, you face new challenges and obstacles every day. Managing the exhausting number of tedious company tasks takes up valuable time that could be used more efficiently. DevLab provides modern business digitalization solutions that allow you to focus on what matters most. Our mission is to help entrepreneurs and business managers run a successful business.          
        </StyledParagraph>
      </StyledAnimationAnnotationBox>
    </StyledContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyImage from '../common/LazyImage';
import ESProjectsLogo from './ESProjectsLogo.jpg';
import NaujosKartosLietuva from './NaujosKartosLietuva.jpg';

const StyledHeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 0 0;
`
const StyledHeader = styled('h2')`
  font-family: DMSans-Bold;
  font-size: 3rem;
  font-weight: 700;

  ${breakpoint('md')`
    font-size: 4rem;
  `}
  ${breakpoint('xl')`
    font-size: 5rem;
  `}
  ${breakpoint('xxl')`
    font-size: 6rem;
  `}
`

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledAnimationAnnotationBox = styled(ScrollAnimation)`
  width: 85%;
  height: auto;
  display: flex;

  ${breakpoint('md')`
    margin: 0 0 8% 0;
  `}
  ${breakpoint('md')`
    width: 70%;
 `}
  ${breakpoint('xxl')`
    width: 60%;
  `}
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Regular;
  color: #5a5f70;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;

  ${breakpoint('xs')`
    font-size: 1.2rem;
    line-height: 2rem;
  `}
  ${breakpoint('md')`
    font-size: 1.4em;
  `}
  ${breakpoint('xxl')`
    font-size: 1.6rem;
    line-height: 2.2rem;
`}
`
export function ESProjects({ children }) {
  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledHeader>EU Projects</StyledHeader>
      </StyledHeaderContainer>
      <div style={{
        flex: 1,
        justifyContent: 'justify-content',
      }}>
        <LazyImage img={ESProjectsLogo} width={'350px'}  alt='EU Projects' />
        <LazyImage img={NaujosKartosLietuva} width={'350px'} alt='EU Projects' />
      </div>
      <StyledAnimationAnnotationBox animateIn='fadeInUp' animateOnce={true}>
        <StyledParagraph>
          <p>UAB "Išskirtinis mąstymas" is going to implement the project "Creation and implementation of environmentally friendly products in the company UAB "Išskirtinis mąstymas" (project No. 02-006-K-0126), the main goal of which is the creation of an environmentally friendly product.</p>

          <p>During the project, an innovative system for measuring the geometric parameters of plastic tape production and compliance with quality will be developed, which will be intended for plastic tape manufacturers.</p>

          <p>Project value: EUR 71,470, of which EUR 50,000 is EU support. The project is financed with the funds of the New Generation Lithuania Plan for Economic Revitalization and Resilience.</p>

          <p>Project start: 2023 July.</p>
          <p>End of the project: 2024 July.</p>
        </StyledParagraph>
      </StyledAnimationAnnotationBox>
    </StyledContainer>
  );
};
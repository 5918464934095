import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Avatar } from './Avatar'
import { Person } from './Person'
import Artiom from './Artiom.jpg';
import Alex from './Alex.jpg';
import Artur from './Artur.jpg';
import Malgo from './Malgo.jpg';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;

  ${breakpoint('xxl')`
    flex-direction: row;
  `}
`
const StyledDiv = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 0 0 3% 0;
`
export function Team() {
  const [isOpenFirstPerson, setIsOpenFirstPerson] = useState(false);
  const [isOpenSecondPerson, setIsOpenSecondPerson] = useState(false);
  const [isOpenThirdPerson, setIsOpenThirdPerson] = useState(false);
  const [isOpenFourthPerson, setIsOpenFourthPerson] = useState(false);

  function openPerson(first, second, third, fourth) {
    setIsOpenFirstPerson(first);
    setIsOpenSecondPerson(second);
    setIsOpenThirdPerson(third);
    setIsOpenFourthPerson(fourth);
  }
  
  return (
    <>
      <StyledContainer>
        <StyledDiv>
          <Avatar
            to='cv'
            img={Artiom}
            primary='Artiom Bezborodych'
            secondary='Founder'
            onOpen={() => openPerson(true, false, false, false)}
          />
          <Avatar
            to='cv'
            delay={400}
            img={Alex}
            primary='Alexandr Avin'
            secondary='BDO'
            onOpen={() => openPerson(false, true, false, false)}
          />
        </StyledDiv>
        <StyledDiv>
          <Avatar
            to='cv'
            delay={800}
            img={Artur}
            primary='Artur Bartkevič'
            secondary='CTO'
            onOpen={() => openPerson(false, false, true, false)}
          />
          <Avatar
            to='cv'
            delay={1200}
            img={Malgo}
            primary='Malgožata Skirtun'
            secondary='CEO'
            onOpen={() => openPerson(false, false, false, true)}
          />
        </StyledDiv>
      </StyledContainer>
      <div id='cv' />
      {isOpenFirstPerson && (
        <Person
          img={Artiom}
          link={'https://www.linkedin.com/in/artiom-bezborodych-20aba513a/'}
          primary='Artiom Bezborodych'
          secondary='Founder'
          paragraph='Highly skilled, result-orientated and driven; Artiom brings with him over 15 years of business expertise in B2B sales. As a leading expert in product manufacturing and management processes, Artiom is valued by partners and colleagues for his customer-centric approach and high level of integrity. Artiom previously led a team of 200 employees and managed to the highest LEAN standards.'
          onClose={() => setIsOpenFirstPerson(false)}
        />
      )}
      {isOpenSecondPerson && (
        <Person
          img={Alex}
          link={'https://www.linkedin.com/in/aleksandr-avin'}
          primary='Alexandr Avin'
          secondary='BDO'
          paragraph='With over 15 years in management and business, Aleksandr knows the ins and outs of building a business from the ground up. His hands-on experience includes managing his own business as well as leading a team of 500 employees as Chief Operations Manager of Olympic Casino. As a manager and business owner, he has successfully implemented advanced automatization, digitalization and LEAN project management techniques.  Aleksandr is devoted to providing quality solutions and championing business integrity.'
          onClose={() => setIsOpenSecondPerson(false)}
        />
      )}
      {isOpenThirdPerson && (
        <Person
          img={Artur}
          link={'https://www.linkedin.com/in/artur-bartkevic-b5035943/'}
          primary='Artur Bartkevič'
          secondary='CTO'
          paragraph='Passionate about building quality solutions and optimizing processes, Artur has over 15 years of professional experience in the IT industry. Artur is currently focusing on remote team organization and their development, as well as creating and executing top-level strategic plans. With industry-specific know-how and extensive expertise in Business Optimization, Artur is an efficiency expert.'
          onClose={() => setIsOpenThirdPerson(false)}
        />
      )}
      {isOpenFourthPerson && (
        <Person
          img={Malgo}
          link={'https://www.linkedin.com/in/malgozata-skirtun-006980134/'}
          primary='Malgožata Skirtun'
          secondary='CEO'
          paragraph='Malgožata is a top-level administrative specialist with over 10 years of experience. During this time, he gained valuable expertise in the fields of communication, organization and sales. Now, his main focus is developing teams and fostering their cooperation to achieve the best possible result in every project.'
          onClose={() => setIsOpenFourthPerson(false)}
        />
      )}
    </>
  );
}
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import { Value } from './Values'
import FirstImg from './FirstImg.png';
import SecondImg from './SecondImg.png';
import ThirdImg from './ThirdImg.png';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;

  ${breakpoint('xl')`
    margin: 8rem 0 12rem 0;
  `}
  ${breakpoint('xxl')`
    margin: 8rem 0 17rem 0;
  `}
`
const StyledValuesContainer = styled('div')`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint('xxl')`
    flex-direction: row;
    align-items: flex-start;
  `}
`
const StyledHeader = styled('h1')`
  font-family: DMSans-Bold;
  color: #1a1a1a;
  font-size: 3.3rem;
  font-weight: 700;
  text-align: center;
  margin: 0 0 2rem 0;

  ${breakpoint('md')`
    margin: 0 0 4rem 0;
  `}
  ${breakpoint('xl')`
    font-size: 4rem;
  `}
  ${breakpoint('xxl')`
    font-size: 6rem;
    margin: 5rem 0;
  `}
`
export function CompanyValues() {
  return (
    <StyledContainer>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
        <StyledHeader>Company Values</StyledHeader>
      </ScrollAnimation>
      <StyledValuesContainer>
        <Value
          img={FirstImg}
          primary='Ownership'
          secondary='Be courageous. Honour commitments. Put the client first.'          
        >
        </Value>
        <Value
          delay={400}
          img={SecondImg}
          primary='Results'
          secondary='Innovate with passion and hard work. Leverage business expertise. Deliver a high-quality product.'
        >
        </Value>        
        <Value
          delay={800}
          img={ThirdImg}
          primary='Team Success'
          secondary='Be a team player. Treat everyone with respect. Strive to win.'          
        >
        </Value>
      </StyledValuesContainer>
    </StyledContainer>
  );
};
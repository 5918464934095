import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { ScrollButton } from '../common/ScrollButton';
import { Form } from './Form'
import { Address } from './Address'

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% 0;

  ${breakpoint('md')`
    margin: 3% auto 1% auto;
  `}
  ${breakpoint('xxl')`
    width: 60%;
  `}
`
const StyledContactContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15% 0 10% 0;
  width: 100%;

  ${breakpoint('md')`
    margin: 8% 0 5% 0;
  `}
  ${breakpoint('lg')`
    margin: 5% 0 3% 0;
  `}
  ${breakpoint('xxl')`
    flex-direction: row;
    justify-content: space-between;
  `}
`
export function Contact() {
  return (
    <StyledContainer>
      <StyledContactContainer>
        <Form />
        <Address />
      </StyledContactContainer>
      <ScrollButton scrollTo='top'>Home</ScrollButton>
    </StyledContainer>
  );
};
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-scroll";
import { Roof, CloseMenuButton } from './Roof'

const StyledAnimationContainer = styled(ScrollAnimation)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.9;
`
const StyledParagraphsContainer = styled('div')`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 0 5% 0;
`
const StyledParagraph = styled('p')`
  font-family: DMSans-Bold;
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin: 0;

  ${breakpoint('xxs')`
    font-size: 1.8rem;
  `}
  ${breakpoint('md')`
    font-size: 2.5rem;
  `}
  ${breakpoint('xl')`
    font-size: 3rem;
  `}
`
export function Menu({onCloseMenu}) {
  return (
    <StyledAnimationContainer animateIn='fadeIn' animateOnce={true}>
      <Roof>
        <CloseMenuButton onCloseMenu={onCloseMenu}/>
      </Roof>
      <StyledParagraphsContainer>
        <Link to='solutions' smooth={true}><StyledParagraph>Solutions</StyledParagraph></Link>
        {/* <Link to='team' smooth={true}><StyledParagraph>Team</StyledParagraph></Link>  waiting for new photos  */}
        <Link to='company_values' smooth={true}><StyledParagraph>Company values</StyledParagraph></Link>
        <Link to='portfolio' smooth={true}><StyledParagraph>Portfolio</StyledParagraph></Link>
        <Link to='feedback' smooth={true}><StyledParagraph>Feedback</StyledParagraph></Link>
        <Link to='es_projects' smooth={true}><StyledParagraph>EU Projects</StyledParagraph></Link>
        <Link to='contact_us' smooth={true}><StyledParagraph>Contact us</StyledParagraph></Link>
      </StyledParagraphsContainer>
    </StyledAnimationContainer>
  );
};